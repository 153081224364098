<template>
  <div class="cityDetails">
    <div class="p_f cd_head">
      <div class="bg_c_black p_a"></div>
      <van-image  fit="cover" width="100%" height="100%" :src="content.cardBackgroundImageUrl" class="top_bg p_a"/>
      <van-nav-bar title="城市名片" class="p_r">
        <template #left>
          <van-icon :size="24" color="#ffffff" name="arrow-left" slot="left" @click="exit()" />
        </template>
      </van-nav-bar>
      <div class="d_f ali_c t_a j_c p_r">
        <div class="d_f ali_c cd_area" @click="showPop = true">
          <span>乐山 · {{name}}</span> <span class="fs_12 right_name"> ▶</span>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model:show="showPop"
      :actions="actions"
      @select="onSelect"
      cancel-text="取消"
      close-on-click-action/>
    <div class="cd_nav">

      <!--简介-->
      <div class="card_shadow">
        <div class="td_banner">
          <div class="td_banner_list" v-for="(item,i) in videos" :key="i">
            <video id="videoPlaywer" class="video-player vjs-custom-skin" controls
                   :poster="item.poster"
                   :src="item.sources[0].src"
                   ref="videoPlayer"
            ></video>
          </div>
          <div class="td_banner_list" v-for=" (item,i) in content.attachments" :key="i" v-show="item.fileType==1">
            <van-image :src="item.url">
              <template v-slot:error>加载失败</template>
            </van-image>
          </div>
        </div>
        <div class="cd_title">
          {{name}}·简介
        </div>
        <div class="cd_cont" style="margin-top: 15px"  :class="textState?'cd_cont_state':''" >
          {{content.synopsis}}
        </div>
        <div class="cd_btn" @click="textState=!textState">
          <span>{{textState?'继续阅读':'收起'}}</span>
        </div>
      </div>
      <!--追溯历史-->
      <div class="card_shadow">
        <div class="td_banner_list" v-if="content.historyImageUrl">
          <van-image  fit="cover" width="100%" height="100%" :src="content.historyImageUrl"/>
        </div>
        <div class="cd_title">
          历史追溯
        </div>
        <div class="cd_history_cont" :class="historyState?'cd_cont_state':''"  v-html="content.history"></div>

        <div class="cd_btn" @click="historyState=!historyState">
          <span>{{historyState?'继续阅读':'收起'}}</span>
        </div>
      </div>
      <div class="cd_ser">
        <div class="cd_ser_title d_f ali_c">
          <span>本地推荐</span>
          <img src="./img/cd_hot.png" alt="">
        </div>
        <div class="tabs_out">
          <div class="d_i tabs_cd p_r cur_p" v-for="(item,index) in tagsList" @click="allList(index)" :class="acTab === index ? 'ac' : ''">
            <span>{{item.name}}</span>
            <img src="./img/cd_tabs_border.png" alt="" class="p_a">
          </div>
        </div>
        <div class="cd_ser_list" v-if="list.length" >
          <div class="cd_ser_list_item" v-for="(item,i) in list" :key="i" @click="routerPush(item)">
            <van-image :src="item.cover" fit="cover">
              <template v-slot:error>
                <img src="./img/noImg.png" style="width: 100%;height: 100%;border-radius: 5px 5px 0 0" alt="">
              </template>
            </van-image>
            <div style="padding:0 15px 9px 15px;">
              <span>{{item.name}}</span>
              <div class="cd_address d_f ali_c" v-if="item.address">
                <img src="./img/cd_address.png" alt="">
                <span>{{item.address}}</span>
              </div>
              <!--<span v-show="item.visitorsNumber">{{item.visitorsNumber}}人感兴趣</span>-->
            </div>

          </div>
        </div>
        <div v-else style="margin: 40px 0 40px 0;display: flex;justify-content: center">
          <img style="width: 250px;height: 190px" src="./img/WechatIMG178.png" alt="">
        </div>
      </div>
    </div>

  </div>
</template>

<script src="./cityDetails.js"></script>
<style scoped src="./cityDetails.css"></style>
